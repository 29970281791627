import React, { useContext, useEffect, useState, useRef } from "react";
import { LoadingContext } from "../bifrost/providers/loading.provider.js";
import useNormalModal from "../bifrost/hooks/useNomralModal.hook.js";
import {
    dateFormater,
    formatDateFromString_MM_DD_YYYY, getModelColors,
    groupedAreaCodes
} from "./helper/dataConvert.helper.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import SearchButton from "./components/SearchButton.jsx";
import LoadingScreen from "../bifrost/LoadingScreen.jsx";
import InfoModal from "../bifrost/InfoModal.jsx";
import modelChart from "./api/modelChart.js";
import StackBarChartModels from "./components/StackBarChartModels.jsx";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const ModelChart = () => {
    const initToDate = new Date();
    const initFromDate = new Date(initToDate.getFullYear(), initToDate.getMonth(), 1);

    const [toDate, setToDate] = useState(initToDate);
    const [fromDate, setFromDate] = useState(initFromDate);
    const { loading, startLoading, stopLoading } = useContext(LoadingContext);
    const [isOpen, toggleModal] = useNormalModal();
    const [responseMessage, setResponseMessage] = useState('');
    const [showBar, setShowBar] = useState(false);
    const [chartData, setChartData] = useState({});
    const [airlines, setAirlines] = useState([]);
    const [option, setOption] = useState(null);
    const [groupData, setGroupData] = useState(null);

    const chartRef = useRef(null);

    const styleOption = {
        width: '100%',
        height: '500px',
        marginTop: '20px'
    };

    useEffect(() => {
        loadChartData();
    }, []);

    useEffect(() => {
        const chart = chartRef.current?.getChart();
        if (chart) {
            chart.update();
        }
    }, [chartData]);

    const checkFormStatus = () => {
        if (!fromDate || !toDate) {
            setResponseMessage('Please input form data.');
            toggleModal(true);
            return false;
        }
        return true;
    };

    const transformData = (data) => {
        const dates = data.map(item => formatDateFromString_MM_DD_YYYY(item.date));

        const models = Array.from(
            new Set(data.flatMap(item => Object.keys(item.models)))
        );

        return {
            labels: dates,
            datasets: models.map((model, index) => {
                const colors = getModelColors(model);
                return {
                    label: model,
                    data: data.map(item => item.models[model] ?? 0),
                    backgroundColor: colors.backgroundColor,
                    borderColor: colors.borderColor,
                    borderWidth: 1,
                };
            }),
        };
    };

    const handleSubmit = () => {
        if (!checkFormStatus()) return;
        loadChartData();
    };

    const loadChartData = () => {
        setShowBar(false);
        startLoading();
        modelChart(dateFormater(fromDate), dateFormater(toDate)).then((response) => {
            if (response.error) {
                setResponseMessage('Something went wrong with model charts data.');
                toggleModal(true);
                stopLoading();
                return;
            }
            const data = response.data;
            if (!Object.entries(data) || Object.entries(data).length === 0) {
                setShowBar(false);
                setChartData({});
                setResponseMessage('No Data Found. Please search again');
                toggleModal(true);
                stopLoading();
                return;
            }
            const groupByAirline = groupedAreaCodes(data, 'name');
            const airlines = groupByAirline.propArray;
            const arrayDataObj = groupByAirline.resultArray;

            const selectedAirline = option && airlines.includes(option) ? option : airlines[0];
            const chartData = transformData(arrayDataObj[selectedAirline]);

            setShowBar(true);
            setGroupData(groupByAirline);
            setAirlines(airlines);
            setOption(selectedAirline);
            setChartData(chartData);
            stopLoading();
        });
    };

    const handleChange = (event) => {
        const value = event?.target?.value;
        setOption(value);
        const chartData = transformData(groupData.resultArray[value]);
        if (!chartData) return;
        setChartData(chartData);
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                stacked: true,
                ticks: {
                    padding: 5
                }
            }],
            yAxes: [{
                stacked: true,
                ticks: {
                    beginAtZero: true,
                    padding: 5
                }
            }]
        },
        tooltips: {
            mode: 'index',
            intersect: false
        },
        layout: {
            padding: {
                left: 10,
                right: 10,
                top: 10,
                bottom: 10
            }
        }
    };

    return (
        <>
            <h3 className="heading">Airline - Models Chart</h3>

            <div className="search-area-container">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        renderInput={(params) => <TextField {...params} variant="outlined"/>}
                        label="From Date"
                        maxDate={toDate}
                        onChange={(value) => {
                            if (!value) return;
                            const newDateValue = new Date(value);
                            if (newDateValue?.getTime() > toDate?.getTime()) {
                                setToDate(newDateValue);
                            }
                            setFromDate(newDateValue);
                        }}
                        value={fromDate}/>
                    <DatePicker
                        minDate={fromDate}
                        renderInput={(params) => <TextField {...params} variant="outlined"/>}
                        label="To Date"
                        onChange={(value) => {
                            if (value) {
                                setToDate(new Date(value));
                            }
                        }}
                        value={toDate}/>
                    <SearchButton handleClick={handleSubmit}/>
                </LocalizationProvider>
            </div>

            {showBar && chartData && (
                <>
                    <div style={{width: '100%', marginTop: '20px', marginBottom: '20px'}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Airlines</InputLabel>
                            <Select
                                value={option}
                                onChange={handleChange}
                                label="Airlines">
                                {airlines.map((airline) => (
                                    <MenuItem key={airline} value={airline}>
                                        {airline}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <div style={styleOption} className="chart-area">
                        <StackBarChartModels
                            data={chartData}
                            options={chartOptions}
                            ref={chartRef}
                        />
                    </div>
                </>
            )}

            <LoadingScreen isLoading={loading}/>

            <InfoModal
                open={isOpen}
                onClose={() => toggleModal(false)}
                title="Response"
                message={responseMessage}
            />
        </>
    );
};

export default ModelChart;