import React, { useContext, useEffect, useState, useRef } from "react";
import { LoadingContext } from "../bifrost/providers/loading.provider.js";
import useNormalModal from "../bifrost/hooks/useNomralModal.hook.js";
import {
    dateFormater,
    formatDateFromString_MM_DD_YYYY,
    groupedAreaCodes,
    getDataSourceColors
} from "./helper/dataConvert.helper.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import SearchButton from "./components/SearchButton.jsx";
import LoadingScreen from "../bifrost/LoadingScreen.jsx";
import InfoModal from "../bifrost/InfoModal.jsx";
import StackBarChartModelsDataSource from "./components/StackBarChartModelsDataSource.jsx";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import getChartModelDatasource from "./api/modelsDatasource.js";
import dataPipelineGetAirlines from "./api/data-pipe-line-getAllAirlines.js";

const ModelsDatasource = () => {
    const initToDate = new Date();
    const initFromDate = new Date(initToDate.getFullYear(), initToDate.getMonth(), 1);

    const [toDate, setToDate] = useState(initToDate);
    const [fromDate, setFromDate] = useState(initFromDate);
    const { loading, startLoading, stopLoading } = useContext(LoadingContext);
    const [open, toggleModal] = useNormalModal();
    const [responseMessage, setResponseMessage] = useState('');
    const [showBar, setShowBar] = useState(false);
    const [chartData, setChartData] = useState({});
    const [airlines, setAirlines] = useState([]);
    const [airline, setAirline] = useState(null);
    const [models, setModels] = useState([]);
    const [selectModel, setSelectModel] = useState(null);
    const [groupData, setGroupData] = useState(null);
    const [dateArray, setDateArray] = useState([]);

    const chartRef = useRef(null);

    const styleOption = {
        width: '100%',
        height: '500px',
        marginTop: '20px'
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                stacked: true,
                ticks: {
                    padding: 5
                }
            }],
            yAxes: [{
                stacked: true,
                ticks: {
                    beginAtZero: true,
                    padding: 5
                }
            }]
        },
        tooltips: {
            mode: 'index',
            intersect: false
        },
        layout: {
            padding: {
                left: 10,
                right: 10,
                top: 10,
                bottom: 10
            }
        }
    };

    useEffect(() => {
        fetchAirlines().then((airline) => {
            if (airline) {
                loadChartData(fromDate, toDate, airline);
            }
        });
    }, []);

    const fetchAirlines = async () => {
        startLoading();
        try {
            const responses = await dataPipelineGetAirlines();
            const airlines = responses?.data || [];
            setAirlines(airlines);
            setAirline(airlines[0] || null);
            return airlines[0] || null;
        } catch (error) {
            console.error('Error fetching airline data:', error);
        } finally {
            stopLoading();
        }
    };

    const checkFormStatus = () => {
        if (!fromDate || !toDate || !airline) {
            setResponseMessage('Please input form data.');
            toggleModal(true);
            return false;
        }
        return true;
    };

    const transformData = (data, dates, modelName) => {
        // Ensure data is an array and not empty
        if (!Array.isArray(data) || data.length === 0) {
            console.error('Invalid or empty data passed to transformData');
            return {
                labels: [],
                datasets: []
            };
        }

        // Extract data for the selected model
        const modelData = data.map(dateItem => {
            const model = dateItem.models.find(m => m.modelName === modelName);
            return model ? model.dataSource : {};
        });

        // Collect all unique data sources from the selected model across all dates
        let allDataSources = new Set();
        modelData.forEach(dataSource => {
            Object.keys(dataSource).forEach(source => {
                allDataSources.add(source);
            });
        });
        const dataSources = Array.from(allDataSources);

        // Prepare datasets for each data source
        return {
            labels: dates,
            datasets: dataSources.map((source) => {
                const colors = getDataSourceColors(source); // Lấy màu từ hàm getModelColors
                return {
                    label: source,
                    data: modelData.map(dataSource => dataSource[source] || 0),
                    backgroundColor: colors.backgroundColor,
                    borderColor: colors.borderColor,
                    borderWidth: 1,
                };
            }),
        };
    };

    const handleSubmit = () => {
        if (!checkFormStatus()) return;
        loadChartData(fromDate, toDate, airline);
    };

    const loadChartData = (fromDate, toDate, airline) => {
        setShowBar(false);
        startLoading();
        getChartModelDatasource(dateFormater(fromDate), dateFormater(toDate), airline).then((response) => {
            stopLoading();
            if (response.error) {
                setResponseMessage('Something went wrong with model charts data.');
                toggleModal(true);
                return;
            }
            const data = response.data;
            if (!data || !Array.isArray(data) || data.length === 0) {
                setShowBar(false);
                setResponseMessage('No Data Found. Please search again');
                toggleModal(true);
                return;
            }

            const dateArrays = data.map(item => formatDateFromString_MM_DD_YYYY(item?.date));
            let modelsObj = [];
            data.forEach(item => {
                if (item?.models) {
                    modelsObj = modelsObj.concat(item.models);
                }
            });

            setDateArray(dateArrays);
            const group = groupedAreaCodes(modelsObj, 'modelName');
            const modelNames = group.propArray;
            setGroupData(data);
            if (modelNames.length > 0) {
                const chartData = transformData(data, dateArrays, modelNames[0]);
                setModels(modelNames);
                setSelectModel(modelNames[0]);
                setChartData(chartData);
                setShowBar(true);
            } else {
                setResponseMessage('No models found for the selected airline.');
                toggleModal(true);
            }
        });
    };

    const handleChangeAirline = (event) => {
        const value = event?.target?.value;
        setAirline(value);
        loadChartData(fromDate, toDate, value);
    };

    const handleChangeModel = (event) => {
        const value = event?.target?.value;
        if (!value) return;
        setSelectModel(value);
        if (groupData) {
            const chartData = transformData(groupData, dateArray, value);
            setChartData(chartData);
        }
    };

    return (
        <>
            <h3 className="heading">Airline - Models Datasource Chart</h3>

            <div className="search-area-container">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        label="From Date"
                        maxDate={toDate}
                        onChange={(value) => {
                            if (!value) return;
                            const newDateValue = new Date(value);
                            if (newDateValue?.getTime() > toDate?.getTime()) {
                                setToDate(newDateValue);
                            }
                            setFromDate(newDateValue);
                        }}
                        value={fromDate} />
                    <DatePicker
                        minDate={fromDate}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        label="To Date"
                        onChange={(value) => {
                            if (value) {
                                setToDate(new Date(value));
                            }
                        }}
                        value={toDate} />
                    <FormControl>
                        <InputLabel shrink>Airlines</InputLabel>
                        <Select sx={{ width: '300px' }}
                                value={airline}
                                onChange={handleChangeAirline}
                                label="Airlines">
                            {airlines.map((airline) => (
                                <MenuItem key={airline} value={airline}>
                                    {airline}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <SearchButton sx={{ padding: '10px' }} handleClick={handleSubmit} />
                </LocalizationProvider>
            </div>

            {
                showBar && chartData ?
                    <>
                        <div style={{ width: '100%', marginTop: '20px' }}>
                            <FormControl fullWidth>
                                <InputLabel>Models</InputLabel>
                                <Select sx={{ width: '300px' }}
                                        value={selectModel}
                                        onChange={handleChangeModel}
                                        label="Models">
                                    {models.map((model) => (
                                        <MenuItem key={model} value={model}>
                                            {model}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div style={styleOption} className="chart-area">
                            <StackBarChartModelsDataSource data={chartData} options={chartOptions} ref={chartRef} />
                        </div>
                    </>

                    : null
            }

            <LoadingScreen isLoading={loading} />

            <InfoModal
                open={open}
                onClose={() => toggleModal(false)}
                title="Response"
                message={responseMessage}
            />
        </>
    );
}

export default ModelsDatasource;
