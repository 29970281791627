import axios from "axios";
import {
    handleSingleResponse,
    handleSuccessResponse,
    RETRY_TIMES,
} from "../../bifrost/api-helpers/api.helper.js";

const totalFileCountChart = async (startDate, endDate) => {
    const apiUrl = process.env.REACT_APP_API_TOTAL_FILE_COUNT_CHART;
    const timeout = 5000;
    const retryTimes =  RETRY_TIMES;
    let count = 1;
    let succeededResponse = null;
    try {
        // try call api at the first time.
        succeededResponse = await axios.get(apiUrl, {params: {startDate, endDate}, timeout});
        return handleSuccessResponse(succeededResponse);
    } catch (e) {
        // as axios will throw an error in case of timeout, need this try-catch block
        // if the first time api call error, try again.
        let result = null;
        while (retryTimes > count) {
            result = await handleSingleResponse(axios.get(apiUrl, {params: {startDate, endDate}, timeout}));
            if (result?.success === true) {
                break;
            } else {
                count++
            }
        }
        return result;
    }
};
export default totalFileCountChart;
