import SearchIcon from "@patternfly/react-icons/dist/esm/icons/search-icon";
import {Button} from "@mui/material";
import React from "react";

const SearchButton = ({handleClick, disabled}) => {

    return (
        <>
            <Button
                variant="contained"
                disabled={disabled}
                startIcon={<SearchIcon />}
                onClick={handleClick}>
                Search
            </Button>
        </>
    );
};

export default SearchButton;
