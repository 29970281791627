export const dateFormater = (date, options, separator) => {
    function format(option) {
        let formatter = new Intl.DateTimeFormat('en', option);
        return formatter.format(date);
    }

    if (!options) {
        options = [{month: '2-digit'}, {day: '2-digit'}, {year: 'numeric'}];
    }
    if (!separator) {
        separator = ''
    }
    return options.map(format).join(separator);
}

export const groupedAreaCodes = (arr, prop) => {
    const propArray = []
    const resultArray = arr.reduce((acc, element) => {
        if (!acc[element[prop]]) {
            acc[element[prop]] = [];
            propArray.push(element[prop])
        }
        acc[element[prop]].push(element);
        return acc;
    }, {});
    return {propArray, resultArray};
}

export const generateRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r}, ${g}, ${b})`;
}

export const formatDateFromString_MM_DD_YYYY = (dateString) => {
    const year = dateString.slice(4);
    const month = dateString.slice(2, 4);
    const day = dateString.slice(0, 2);
    return `${month}-${day}-${year}`;
}

export const getRandomColors = (n) => {
    const selectedColors = [];

    for (let i = 0; i < n; i++) {
        const randomIndex = Math.floor(Math.random() * contrastColors.length);
        const color = contrastColors[randomIndex];
        selectedColors.push(color);
    }

    return selectedColors;
}
export const borderColors = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)'
];

export const contrastColors = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)'
];


export const generateMatchingColors = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    const backgroundColor = `rgba(${r}, ${g}, ${b}, 0.2)`;
    const borderColor = `rgba(${r}, ${g}, ${b}, 1)`;

    return { backgroundColor, borderColor };
};

const modelColors = {
    "777": {
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)'
    },
    "737": {
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)'
    },
    "787": {
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)'
    },
    "737NG": {
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)'
    }
};


const dataSourceColors = {
    "bifrost": {
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)'
    },
    "Bifrost": {
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)'
    },
    "maestro": {
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
        borderColor: 'rgba(255, 206, 86, 1)'
    },
    "gcp-data": {
        backgroundColor: 'rgba(152, 251, 152, 0.2)',
        borderColor: 'rgba(152, 251, 152, 1)'
    }
};

const usedColors = new Set([
    ...Object.values(modelColors).map(color => color.backgroundColor),
    ...Object.values(dataSourceColors).map(color => color.backgroundColor)
]);


export const getModelColors = (model) => {
    if (modelColors[model]) {
        return modelColors[model];
    } else {
        let backgroundColor, borderColor;
        let isUnique = false;

        while (!isUnique) {
            const r = Math.floor(Math.random() * 256);
            const g = Math.floor(Math.random() * 256);
            const b = Math.floor(Math.random() * 256);

            backgroundColor = `rgba(${r}, ${g}, ${b}, 0.2)`;
            borderColor = `rgba(${r}, ${g}, ${b}, 1)`;

            if (!usedColors.has(backgroundColor)) {
                isUnique = true;
                usedColors.add(backgroundColor);
            }
        }

        return { backgroundColor, borderColor };
    }
};

export const getDataSourceColors = (dataSource) => {
    if (dataSourceColors[dataSource]) {
        return dataSourceColors[dataSource];
    } else {
        let backgroundColor, borderColor;
        let isUnique = false;

        while (!isUnique) {
            const r = Math.floor(Math.random() * 256);
            const g = Math.floor(Math.random() * 256);
            const b = Math.floor(Math.random() * 256);

            backgroundColor = `rgba(${r}, ${g}, ${b}, 0.2)`;
            borderColor = `rgba(${r}, ${g}, ${b}, 1)`;

            if (!usedColors.has(backgroundColor)) {
                isUnique = true;
                usedColors.add(backgroundColor);
            }
        }

        return { backgroundColor, borderColor };
    }
};