import React, { useEffect, useRef } from 'react';
import styles from "@patternfly/react-styles/css/components/MultipleFileUpload/multiple-file-upload"
import { css } from "@patternfly/react-styles"
import { Progress, Chip, Badge, Button, ProgressMeasureLocation } from '@patternfly/react-core';
import { BlobServiceClient } from "@azure/storage-blob";
import FileIcon from "@patternfly/react-icons/dist/esm/icons/file-icon"
import TimesCircleIcon from "@patternfly/react-icons/dist/esm/icons/times-circle-icon"
import UploadIcon from "@patternfly/react-icons/dist/esm/icons/upload-icon";
import { Exception } from 'sass';

const { v4: uuidv4 } = require('uuid');
var base64 = require('uuid-base64');

export const QarUploadStatus = ({
    className,
    file,
    fileIcon,
    airline,
    sasToken,
    dirName,
    onReadStarted = () => { },
    onReadFinished = () => { },
    onReadSuccess = () => { },
    onReadFail = () => { },
    onClearClick = () => { },
    customFileHandler,
    fileName,
    fileSize,
    progressValue,
    progressAriaLabel,
    progressAriaLabelledBy,
    progressId,
    progressAriaLiveMessage,
    buttonAriaLabel = "Remove from list",
    progressHelperText,
    ...props
}) => {
    const [loadPercentage, setLoadPercentage] = React.useState(0)
    const [loadResult, setLoadResult] = React.useState()
    const [loadedBytes, setLoadedBytes] = React.useState("")
    const maxRetries = useRef(0)

    const [selectedVariant, setSelectedVariant] = React.useState(undefined);
    const abortSignal = useRef(false);
    const [retryLabel, setRetryLabel] = React.useState("")
    const [cancelLabel, setCancelLabel] = React.useState("Cancel")


    function readFile(file, chunkSize, callback) {
        return new Promise((resolve, reject) => {

            let size = file.size;
            let offset = 0;
            let chunk = file.slice(offset, offset + chunkSize);

            let hashChunk = function () {
                let reader = new FileReader();
                reader.onload = (e) => {
                    // Increment hash

                    // Update offset for next chunk
                    offset += chunkSize;

                    // Hash next chunk if available
                    if (offset < size) {
                        // Splice the next Blob from the File
                        chunk = file.slice(offset, offset + chunkSize);
                        // Recurse to hash next chunk
                        hashChunk();
                        // Done hashing
                    } else {
                        // Report digest
                        resolve(reader.result);


                    }
                };

                reader.onerror = () => {
                    reject(reader.error);
                    console.log(reader.error)
                }
                reader.onprogress = data => {
                    if (data.lengthComputable) {
                        console.log(data.lengthComputable)
                        console.log((data.loaded / data.total) * 100)
                    }
                }

                reader.readAsArrayBuffer(chunk);
            };

            // Start hashing chunks
            hashChunk();
        })
    }

    useEffect(() => {
        if (customFileHandler) {
            customFileHandler(file)
        } else {
            onReadStarted(file)
            readFile(file, 1024 * 1024 * 1024)
                .then(data => {

                    setLoadResult("success")
                    //setLoadPercentage(100)
                    onReadFinished(file)
                    onReadSuccess(data, file)
                    upload(file, sasToken)

                })
                .catch(error => {
                    onReadFinished(file)
                    onReadFail(error, file)
                    setLoadResult("danger")
                })
        }
    }, [])

    const upload = async (file, sasToken) => {
        try {
            console.log(dirName)
            const chunkSize = (1024 * 1024);  // file chunk size
            let chunks = []
            chunks = createChunks(file, chunkSize)
            let progress = 0
            // Create a new BlobServiceClient with SAS            
            const blobServiceUri = `https://${process.env.REACT_APP_TEMP_STORAGE_ACCOUNT}.blob.core.windows.net`;

            const blobServiceClient = new BlobServiceClient(
                `${blobServiceUri}?${sasToken}`,
                null
            );
            // Create a unique name for the container by 
            // appending the current time to the file name
            const containerName = "https";

            // Get a container client from the BlobServiceClient
            const containerClient = blobServiceClient.getContainerClient(containerName);

            const blockBlobClient = containerClient.getBlockBlobClient(`${airline.toUpperCase()}${dirName}${file.name}`);

            let ids = []
            const res = null

            for (let [index, chunk] of chunks.entries()) {
                progress = ((index + 1) / chunks.length) * 100
                setLoadPercentage(progress)

                var id = btoa(uuidv4());
                //console.log(id)
                ids.push(id)
                if (abortSignal.current == false) {
                    await blockBlobClient.stageBlock(id, chunk, chunk.size, {
                        onProgress: e => {
                            //console.log('onProgress loadedBytes: ' + e.loadedBytes);
                            setLoadedBytes("Staged block " + id + " => " + e.loadedBytes + " bytes")
                        }

                    });
                }
                else {
                    throw new Error('Upload aborted');
                }
            };

            await blockBlobClient.commitBlockList(ids)
            setSelectedVariant('success')
            setLoadedBytes("All blocks committed")
            setRetryLabel()

        }
        catch (e) {
            console.log(e)
            setRetryLabel("Retry")
            setSelectedVariant('danger')
            setLoadedBytes("Aborted")
            if (maxRetries.current < 3) {
                maxRetries.current = maxRetries.current + 1
                upload(file, sasToken)
            }
        }
    }

    const abort = async (e) => {
        try {
            e.preventDefault()
            console.log("abort")
            abortSignal.current = true
        }
        catch (e) {
            console.log(e)
        }
    }

    const close = async (e) => {
        abort()
        onClearClick()
    }

    const retry = async (e) => {
        e.preventDefault()
        setRetryLabel()
        console.log("retry upload")
        abortSignal.current = false
        setSelectedVariant()
        upload(file, sasToken)
    }

    const createChunks = (file, cSize/* cSize should be byte 1024*1 = 1KB */) => {
        let startPointer = 0;
        let endPointer = file.size;
        let chunks = [];
        while (startPointer < endPointer) {
            let newStartPointer = startPointer + cSize;
            chunks.push(file.slice(startPointer, newStartPointer));
            startPointer = newStartPointer;
        }
        return chunks;
    }

    const getHumanReadableFileSize = size => {
        const prefixes = ["", "K", "M", "G", "T"]
        let prefixUnit = 0
        while (size >= 1000) {
            prefixUnit += 1
            size = size / 1000
        }

        if (prefixUnit >= prefixes.length) {
            return "File size too large"
        }

        return `${Math.round(size)}${prefixes[prefixUnit]}B`
    }

    //const value = progressValue || loadPercentage
    //const variant = progressVariant || loadResult

    const title = (
        <span className={styles.multipleFileUploadStatusItemProgress}>
            <span>
                {fileName || file.name || ""}
            </span>
            <span >
                {fileSize || getHumanReadableFileSize(file.size || 0)}
            </span>
        </span>
    )

    return (
        <li
            className={css(styles.multipleFileUploadStatusItem, className)}
            {...props}
        >
            <div className={styles.multipleFileUploadStatusItemIcon}>
                {fileIcon || <FileIcon />}
            </div>
            <div className={styles.multipleFileUploadStatusItemMain}>
                <div className="pf-v5-screen-reader" aria-live="polite">
                    {progressAriaLiveMessage &&
                        typeof progressAriaLiveMessage === "function" &&
                        progressAriaLiveMessage(+loadPercentage.toFixed(2))}
                    {progressAriaLiveMessage &&
                        typeof progressAriaLiveMessage === "string" &&
                        progressAriaLiveMessage}
                    {!progressAriaLiveMessage &&
                        `Progress value is ${progressValue ||
                        Math.floor(loadPercentage)}%.`}
                </div>

                <Progress
                    title={title}
                    value={loadPercentage}
                    variant={selectedVariant}
                    id={progressId}
                    helperText={loadedBytes}
                //measureLocation={ProgressMeasureLocation.inside}
                />
                <a onClick={abort} href="">{"Abort"}</a>
                <span>{"  "}</span>
                <a onClick={retry} href="">{retryLabel}</a>
            </div>
            <div className={styles.multipleFileUploadStatusItemClose}>
                <Button
                    variant="plain"
                    aria-label={buttonAriaLabel}
                    onClick={close}
                >
                    <TimesCircleIcon />
                </Button>
            </div>
        </li>
    )
}