import React, { useContext, useEffect, useState, useRef } from "react";
import { Bar } from 'react-chartjs-2';
import SearchButton from "./components/SearchButton.jsx";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from "@mui/material/TextField";
import "./css/DataPipelineChart.css";
import { dateFormater, generateMatchingColors  } from "./helper/dataConvert.helper.js";
import LoadingScreen from "../bifrost/LoadingScreen.jsx";
import { LoadingContext } from "../bifrost/providers/loading.provider.js";
import totalFileCountChart from "./api/totalFileCount.js";
import InfoModal from "../bifrost/InfoModal.jsx";
import useNormalModal from "../bifrost/hooks/useNomralModal.hook.js";

const TotalFileChart = () => {
    const initToDate = new Date();
    const initFromDate = new Date(initToDate.getFullYear(), initToDate.getMonth(), 1);
    const [toDate, setToDate] = useState(initToDate);
    const [fromDate, setFromDate] = useState(initFromDate);
    const { loading, startLoading, stopLoading } = useContext(LoadingContext);
    const [chartData, setChartData] = useState({});
    const [open, toggleModal] = useNormalModal();
    const [responseMessage, setResponseMessage] = useState('');
    const [showBar, setShowBar] = useState(false);

    const chartRef = useRef(null);

    const styleOption = { width: '100%', marginTop: '20px' };

    const generateMatchingColorsArray = (length) => {
        return Array.from({ length }, () => generateMatchingColors());
    };

    useEffect(() => {
        loadChartData();
    }, []);

    const handleSubmit = () => {
        if (!checkFormStatus()) return;
        loadChartData();
    };

    const checkFormStatus = () => {
        if (!fromDate || !toDate) {
            setResponseMessage('Please input form data.');
            toggleModal(true);
            return false;
        }
        return true;
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        tooltips: {
            enabled: true,
            mode: 'index',
            intersect: false,
        },
        hover: {
            mode: 'index',
            intersect: false,
            onHover: (event, chartElements) => {
                if (chartElements && chartElements.length) {
                    event.target.style.cursor = 'pointer';
                } else {
                    event.target.style.cursor = 'default';
                }
            },
        },
        scales: {
            xAxes: [{
                stacked: true,
                ticks: {
                    padding: 5,
                }
            }],
            yAxes: [{
                stacked: true,
                ticks: {
                    beginAtZero: true,
                    padding: 5,
                }
            }]
        }
    };

    const loadChartData = () => {
        setShowBar(false);
        startLoading();
        totalFileCountChart(dateFormater(fromDate), dateFormater(toDate)).then((response) => {
            if (response.error) {
                setResponseMessage('Something went wrong.');
                toggleModal(true);
                stopLoading();
                return;
            }
            const data = response.data;

            if (!data || Object.keys(data).length === 0) {
                setShowBar(false);
                setResponseMessage('No Data Found. Please search again');
                toggleModal(true);
                stopLoading();
                return;
            }

            const labels = [];
            const datasetsData = [];
            for (const [key, value] of Object.entries(data)) {
                labels.push(key);
                datasetsData.push(value ?? 0); // avoid undefined
            }

            const colors = generateMatchingColorsArray(datasetsData.length);

            const chartData = {
                labels,
                datasets: [{
                    label: 'Total File Count',
                    data: datasetsData,
                    backgroundColor: colors.map(color => color.backgroundColor),
                    borderColor: colors.map(color => color.borderColor),
                    borderWidth: 2,
                }]
            };
            setChartData(chartData);
            setShowBar(true);
            stopLoading();
        });
    };

    return (
        <>
            <h3 className="heading">Airline - Total File Count</h3>

            <div className="search-area-container">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        label="From Date"
                        maxDate={toDate}
                        onChange={(value) => {
                            if (!value) return;
                            const newDateValue = new Date(value);
                            if (newDateValue?.getTime() > toDate?.getTime()) {
                                setToDate(newDateValue);
                            }
                            setFromDate(newDateValue);
                        }}
                        value={fromDate} />
                    <DatePicker
                        minDate={fromDate}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        label="To Date"
                        onChange={(value) => {
                            if (value) {
                                setToDate(new Date(value));
                            }
                        }}
                        value={toDate} />
                    <SearchButton handleClick={handleSubmit} />
                </LocalizationProvider>
            </div>

            {showBar && (
                <div style={styleOption} className="chart-area">
                    <Bar
                        data={chartData}
                        options={chartOptions}
                        ref={chartRef}
                    />
                </div>
            )}

            <LoadingScreen isLoading={loading} />

            <InfoModal
                open={open}
                onClose={() => toggleModal(false)}
                title="Response"
                message={responseMessage}
            />
        </>
    );
};

export default TotalFileChart;
