import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse, Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { getAllGroups } from "../../graph";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AirlinesIcon from '@mui/icons-material/Airlines';
import SpeedIcon from '@mui/icons-material/Speed';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import BarChartIcon from '@mui/icons-material/BarChart';

const { v4: uuidv4 } = require('uuid');

class Sidebar extends Component {


  constructor(props) {
    super(props)
    this.state = {
      airlines: []
    };
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      //this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        //this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }

  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      //this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/usermanagement', state: 'userManagementOpen' },
      { path: '/flightdatarecords', state: 'fdrFilesOpen' },
      { path: '/airlinemanagement', state: 'airlineManagementOpen' },
      { path: '/fdalite', state: 'optimalCIOpen' },
      { path: '/Bifrost', state: 'bifrostOpen' },
      { path: '/DataPipeline', state: 'dataPipelineOpen' }
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }

  getAirlines() {
    let airlines = [];
    getAllGroups(this.props.token).then(response => {
      response.value.forEach(group => {
        if (group.displayName.startsWith("airline") == true) {
          airlines.push(group.displayName);
        }
      });
      airlines.sort();
      this.setState({ airlines: airlines });
    });
  }

  render() {
    return (
      <ThemeProvider theme={this.props.theme}>
        <CssBaseline />
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
          <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
            <a className="sidebar-brand brand-logo" href="">   <img height="45px" width="200px" src={require('../../assets/images/logo.png')} /></a>
            <a className="sidebar-brand brand-logo-mini" href=""><img src='https://nextek.com/wp-content/uploads/2014/10/boeing-logo.png' alt="logo" /></a>

          </div>
          <ul className="nav">
            {
              this.props.membership != "airline-fda"
                ?
                <li className="nav-item profile">
                  <div className="profile-desc">
                    <div className="profile-pic">
                      <div className="">
                        <span className="count bg-success"></span>
                      </div>
                      <div className="profile-name">
                        <h5 className="mb-0 font-weight-normal"><Trans>{this.props.account}</Trans></h5>
                        <span className='text-warning'><Trans>{this.props.airline.replace("airline-", "").toUpperCase()}</Trans></span>
                      </div>

                    </div>

                    {/*
              <Dropdown alignRight>
                <Dropdown.Toggle as="a" className="cursor-pointer no-caret">
                  <i className="mdi mdi-dots-vertical"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="sidebar-dropdown preview-list">
                  <a href="!#" className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-settings text-primary"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small"><Trans>Account settings</Trans></p>
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="!#" className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-onepassword  text-info"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small"><Trans>Change Password</Trans></p>
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="!#" className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-calendar-today text-success"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1 text-small"><Trans>To-do list</Trans></p>
                    </div>
                  </a>
                </Dropdown.Menu>
              </Dropdown>
            */}
                  </div>
                </li>
                :
                <li>
                  <div className="nav-item nav-category">
                    <TextField
                      key={uuidv4()} /* fixed issue */
                      show={false}
                      select
                      label="Airline Mode"
                      value={this.props.airline}
                      onChange={e => {
                        //getAirlinePreferences(e.target.value);
                        this.props.setAirline(e.target.value);
                      }}
                      helperText="Fetch data from another airline"
                      variant="standard"
                    >
                      {
                        this.state.airlines
                          ?
                          this.state.airlines.map((airline) => (
                            <MenuItem key={airline} value={airline}>
                              {airline}
                            </MenuItem>
                          ))
                          :
                          <></>
                      }
                    </TextField>
                  </div>
                </li>
            }
            <li className="nav-item nav-category">
              <span className="nav-link"><Trans>Navigation</Trans></span>
            </li>
            <li className={this.isRootPath('/') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
              <Link className="nav-link" to="/">
                <span className="menu-icon"><i className="text-danger">
                  <SpeedIcon />
                </i>
                </span>
                <span className="menu-title"><Trans>Dashboard</Trans></span>
              </Link>
            </li>

            <li className={this.isPathActive('/usermanagement') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
              <div className={this.state.userManagementOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('userManagementOpen')} data-toggle="collapse">
                <span className="menu-icon">
                  <i className="text-warning">
                    <PeopleAltIcon />
                  </i>
                </span>
                <span className="menu-title"><Trans>User Management</Trans></span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.userManagementOpen}>
                <div>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item"> <Link className={this.isPathActive('/usermanagement/UserGrid') ? 'nav-link active' : 'nav-link'} to="/usermanagement/UserGrid"><Trans>Users</Trans></Link></li>
                    <li className="nav-item"> <Link className={this.isPathActive('/usermanagement/BulkLoad') ? 'nav-link active' : 'nav-link'} to="/usermanagement/BulkLoad"><Trans>Bulk Load</Trans></Link></li>
                    {
                      this.props.role == "superadmin"
                        ?
                        <>
                          <li className="nav-item"> <Link className={this.isPathActive('/usermanagement/Preferences') ? 'nav-link active' : 'nav-link'} to="/usermanagement/Preferences"><Trans>Preferences</Trans></Link></li>
                        </>
                        :
                        <></>
                    }
                  </ul>
                </div>
              </Collapse>
            </li>
            {
              this.props.membership == "airline-fda" || this.props.role == "uploadfocal"
                ?
                <li className={this.isPathActive('/airlinemanagement/Airlines') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                  <div className={this.state.airlineManagementOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('airlineManagementOpen')} data-toggle="collapse">
                    <span className="menu-icon">
                      <i className=" text-success">
                        <AirlinesIcon />
                      </i>
                    </span>
                    <span className="menu-title"><Trans>Airline Management</Trans></span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.airlineManagementOpen}>
                    <div>
                      <ul className="nav flex-column sub-menu">
                        <li className="nav-item"> <Link className={this.isPathActive('/airlinemanagement/Airlines') ? 'nav-link active' : 'nav-link'} to="/airlinemanagement/Airlines"><Trans>Setup</Trans></Link></li>
                      </ul>
                      <ul className="nav flex-column sub-menu">
                        <li className="nav-item"> <Link className={this.isPathActive('/airlinemanagement/Fleets') ? 'nav-link active' : 'nav-link'} to="/airlinemanagement/Fleets"><Trans>Onboarding</Trans></Link></li>
                      </ul>
                      <ul className="nav flex-column sub-menu">
                        <li className="nav-item"> <Link className={this.isPathActive('/airlinemanagement/FlightData') ? 'nav-link active' : 'nav-link'} to="/airlinemanagement/FlightData"><Trans>Flight Data</Trans></Link></li>
                      </ul>
                    </div>
                  </Collapse>
                </li>
                :
                <></>
            }
            {/*
              this.props.role == "superadmin"
                ?
                <li className={this.isPathActive('/fdalite/OptimalCI') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                  <div className={this.state.optimalCIOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('optimalCIOpen')} data-toggle="collapse">
                    <span className="menu-icon">
                      <i className="text-info">
                        <FlightTakeoffIcon/>
                      </i>
                    </span>
                    <span className="menu-title"><Trans>FDA Lite</Trans></span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.optimalCIOpen}>
                    <div>
                      <ul className="nav flex-column sub-menu">
                        <li className="nav-item"> <Link className={this.isPathActive('/fdalite/OptimalCI') ? 'nav-link active' : 'nav-link'} to="/fdalite/OptimalCI"><Trans>Optimal CI</Trans></Link></li>
                      </ul>
                    </div>
                  </Collapse>
                </li>
                :
                <></>
          */}
            {
              this.props.membership === "airline-fda"
                ?
                <li className={this.isRootPath('/Bifrost') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                  <div className={this.state.bifrostOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('bifrostOpen')} data-toggle="collapse">
                    <span className="menu-icon">
                      <i className="text-primary">
                        <ViewStreamIcon />
                      </i>
                    </span>
                        <span className="menu-title"><Trans>Bifrost</Trans></span>
                        <i className="menu-arrow"></i>
                      </div>
                      <Collapse in={this.state.bifrostOpen}>
                        <div>
                          <ul className="nav flex-column sub-menu">
                            <li className="nav-item"> <Link className={this.isPathActive('/Bifrost') ? 'nav-link active' : 'nav-link'} to="/Bifrost"><Trans>Batch Service</Trans></Link></li>
                          </ul>
                        </div>
                      </Collapse>
                    </li>
                    :
                    <></>
              }

              {
                this.props.membership === "airline-fda"
                    ?
                    <li className={this.isRootPath('/DataPipeline') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                      <div className={this.state.dataPipelineOpen ? 'nav-link menu-expanded' : 'nav-link'}
                           onClick={() => this.toggleMenuState('dataPipelineOpen')} data-toggle="collapse">
                    <span className="menu-icon">
                      <i className="text-white">
                        <BarChartIcon />
                      </i>
                    </span>
                        <span className="menu-title">
                        <Trans>Data pipeline</Trans>
                      </span>
                        <i className="menu-arrow"></i>
                      </div>
                      <Collapse in={this.state.dataPipelineOpen}>
                        <div>
                          <ul className="nav flex-column sub-menu">
                            <li className="nav-item">
                              <Link
                                  className={this.isPathActive('/DataPipeline/totalFile') ? 'nav-link active' : 'nav-link'}
                                  to="/DataPipeline/totalFile">
                                <Trans>Airlines Chart</Trans>
                              </Link>
                            </li>

                            <li className="nav-item">
                              <Link
                                  className={this.isPathActive('/DataPipeline/modelsChart') ? 'nav-link active' : 'nav-link'}
                                  to="/DataPipeline/modelsChart">
                                <Trans>Models Chart</Trans>
                              </Link>
                            </li>

                            <li className="nav-item">
                              <Link
                                  className={this.isPathActive('/DataPipeline/modelDatasource') ? 'nav-link active' : 'nav-link'}
                                  to="/DataPipeline/modelDatasource">
                                <Trans>Models Detail Chart</Trans>
                              </Link>
                            </li>

                            <li className="nav-item">
                              <Link
                                  className={this.isPathActive('/DataPipeline/datasourceSummary') ? 'nav-link active' : 'nav-link'}
                                  to="/DataPipeline/datasourceSummary">
                                <Trans>Datasource Chart</Trans>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Collapse>
                    </li>
                    :
                    <></>
              }
            </ul>
          </nav>
        </ThemeProvider>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  isRootPath(path) {
    return this.props.location.pathname === path
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
    this.getAirlines();
  }

}

export default withRouter(Sidebar);