import axios from "axios";
import {handleSingleResponse, handleSuccessResponse} from "../../bifrost/api-helpers/api.helper.js";

const apiGetAllAirlineUrl = process.env.REACT_APP_API_GET_ALL_AIRLINE_DATA;

const dataPipelineGetAirlines = async (option) => {
    const timeout = option?.timeout || 5000;
    const retryTimes = option?.retryTimes || 2;
    let count = 1;
    let succeededResponse = null;
    try {
        // try call api at the first time.
        succeededResponse = await axios.get(apiGetAllAirlineUrl, {timeout});
        return handleSuccessResponse(succeededResponse);
    } catch (e) {
        // as axios will throw an error in case of timeout, need this try-catch block
        // if the first time api call error, try again.
        let result = null;
        while (retryTimes > count) {
            result = await handleSingleResponse(axios.get(apiGetAllAirlineUrl));
            if (result?.success === true) {
                break;
            } else {
                count++
            }
        }
        return result;
    }
};
export default dataPipelineGetAirlines;
