import React, { useContext, useEffect, useState, useRef } from "react";
import { LoadingContext } from "../bifrost/providers/loading.provider.js";
import useNormalModal from "../bifrost/hooks/useNomralModal.hook.js";
import {
    dateFormater,
    formatDateFromString_MM_DD_YYYY,
    groupedAreaCodes,
    getDataSourceColors
} from "./helper/dataConvert.helper.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import SearchButton from "./components/SearchButton.jsx";
import LoadingScreen from "../bifrost/LoadingScreen.jsx";
import InfoModal from "../bifrost/InfoModal.jsx";
import StackBarChartDataSource from "./components/StackBarChartDataSource.jsx";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import datasourceSummary from "./api/datasourceSummary.js";

const DatasourceSummaryChart = () => {
    const initToDate = new Date();
    const initFromDate = new Date(initToDate.getFullYear(), initToDate.getMonth(), 1);

    const [toDate, setToDate] = useState(initToDate);
    const [fromDate, setFromDate] = useState(initFromDate);
    const { loading, startLoading, stopLoading } = useContext(LoadingContext);
    const [open, toggleModal] = useNormalModal();
    const [responseMessage, setResponseMessage] = useState('');
    const [showBar, setShowBar] = useState(false);
    const styleOption = { width: '100%', height: '500px', marginTop: '20px' };
    const [chartData, setChartData] = useState({});
    const [airlines, setAirlines] = useState([]);
    const [option, setOption] = useState(null);
    const [groupData, setGroupData] = useState(null);

    const chartRef = useRef(null);

    useEffect(() => {
        loadChartData();
    }, []);

    const checkFormStatus = () => {
        if (!fromDate || !toDate) {
            setResponseMessage('Please input form data.');
            toggleModal(true);
            return false;
        }
        return true;
    };

    const transformData = (data) => {
        const dates = data.map(item => formatDateFromString_MM_DD_YYYY(item.date));

        // Collect all unique datasources from the entire dataset
        const datasources = Array.from(new Set(data.flatMap(item => Object.keys(item.dataSource))));

        return {
            labels: dates,
            datasets: datasources.map((str) => {
                const colors = getDataSourceColors(str);
                return {
                    label: str,
                    data: data.map(item => item.dataSource[str] || 0),
                    backgroundColor: colors.backgroundColor,
                    borderColor: colors.borderColor,
                    borderWidth: 1,
                };
            }),
        };
    };


    const handleSubmit = async () => {
        if (!checkFormStatus()) return;
        loadChartData();
    };

    const handleChange = (event) => {
        const value = event?.target?.value;
        setOption(value);
        const chartData = transformData(groupData.resultArray[value]);
        if (!chartData) return;
        setChartData(chartData);
    };

    const loadChartData = () => {
        setShowBar(false);
        startLoading();
        datasourceSummary(dateFormater(fromDate), dateFormater(toDate)).then((response) => {
            if (!response || response?.error) {
                setResponseMessage('Something went wrong with Datasource summary charts data.');
                toggleModal(true);
                stopLoading();
                return;
            }
            const data = response?.data;
            if (!Object.entries(data) || Object.entries(data).length === 0) {
                setShowBar(false);
                setResponseMessage('No Data Found. Please try again.');
                toggleModal(true);
                stopLoading();
                return;
            }
            const groupByAirline = groupedAreaCodes(data, 'name');
            const airlines = groupByAirline.propArray;
            const arrayDataObj = groupByAirline.resultArray;

            const selectedAirline = option && airlines.includes(option) ? option : airlines[0];
            const chartData = transformData(arrayDataObj[selectedAirline]);

            setShowBar(true);
            setGroupData(groupByAirline);
            setAirlines(airlines);
            setOption(selectedAirline);
            setChartData(chartData);
            stopLoading();
        });
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
            mode: 'index',
            intersect: false,
            custom: function (tooltipModel) {
                const tooltipEl = document.getElementById('chartjs-tooltip');
                if (!tooltipEl) return;

                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                if (tooltipModel.body) {
                    const titleLines = tooltipModel.title || [];
                    const bodyLines = tooltipModel.body.map(b => b.lines);

                    let innerHtml = '<div class="chartjs-tooltip-title">';
                    titleLines.forEach(title => {
                        innerHtml += '<span>' + title + '</span>';
                    });
                    innerHtml += '</div><div class="chartjs-tooltip-body">';

                    bodyLines.forEach((body, i) => {
                        const colors = tooltipModel.labelColors[i];
                        const style = 'background:' + colors.backgroundColor;
                        const span = '<span class="chartjs-tooltip-color-box" style="' + style + '"></span>';
                        innerHtml += '<div>' + span + body + '</div>';
                    });
                    innerHtml += '</div>';

                    tooltipEl.innerHTML = innerHtml;
                }

                const position = chartRef.current?.chartInstance?.canvas.getBoundingClientRect();
                if (position) {
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                }
            }
        },
        scales: {
            xAxes: [{
                stacked: true,
                barPercentage: 0.9,
                categoryPercentage: 0.9,
                ticks: {
                    padding: 5
                }
            }],
            yAxes: [{
                stacked: true,
                ticks: {
                    beginAtZero: true,
                    padding: 5
                }
            }]
        },
        layout: {
            padding: {
                left: 10,
                right: 10,
                top: 10,
                bottom: 10
            }
        }
    };

    return (
        <>
            <h3 className="heading">Datasource Summary Chart</h3>

            <div className="search-area-container">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        label="From Date"
                        maxDate={toDate}
                        onChange={(value) => {
                            if (!value) return;
                            const newDateValue = new Date(value);
                            if (newDateValue?.getTime() > toDate?.getTime()) {
                                setToDate(newDateValue);
                            }
                            setFromDate(newDateValue);
                        }}
                        value={fromDate} />
                    <DatePicker
                        minDate={fromDate}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        label="To Date"
                        onChange={(value) => {
                            if (value) {
                                setToDate(new Date(value));
                            }
                        }}
                        value={toDate} />
                    <SearchButton handleClick={handleSubmit} />
                </LocalizationProvider>
            </div>

            {
                showBar && chartData && (
                    <>
                        <div style={{ width: '100%', marginTop: '20px' }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Airlines</InputLabel>
                                <Select
                                    value={option}
                                    onChange={handleChange}
                                    label="Airlines">
                                    {airlines.map((airline) => (
                                        <MenuItem key={airline} value={airline}>
                                            {airline}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div style={styleOption} className="chart-area">
                            <StackBarChartDataSource
                                data={chartData}
                                options={chartOptions}
                                ref={chartRef}
                            />
                        </div>
                    </>
                )
            }

            <LoadingScreen isLoading={loading} />

            <InfoModal
                open={open}
                onClose={() => toggleModal(false)}
                title="Response"
                message={responseMessage}
            />
        </>
    );
};

export default DatasourceSummaryChart;
