// REACT_APP_API_DATASOURCE_SUMMARY

import axios from "axios";
import {
    handleSingleResponse,
    handleSuccessResponse,
} from "../../bifrost/api-helpers/api.helper.js";

const getDataSourceSummary = async (startDate, endDate) => {
    const apiUrl = process.env.REACT_APP_API_DATASOURCE_SUMMARY;
    const timeout = 3000;
    const retryTimes = 1;
    let count = 1;
    let succeededResponse = null;
    try {
        succeededResponse = await axios.get(apiUrl, {params: {startDate, endDate}, timeout});
        return handleSuccessResponse(succeededResponse);
    } catch (e) {
        let result = null;
        while (retryTimes > count) {
            result = await handleSingleResponse(axios.get(apiUrl, {params: {startDate, endDate}, timeout}));
            if (result?.success === true) {
                break;
            } else {
                count++
            }
        }
        return result;
    }
};
export default getDataSourceSummary;
