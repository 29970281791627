import React, { forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { Bar } from "react-chartjs-2";

const StackBarChartModels = forwardRef(({ data, options }, ref) => {
    const chartRef = useRef(null);
    const tooltipRef = useRef(null);

    useImperativeHandle(ref, () => ({
        getChart: () => chartRef.current?.chartInstance
    }));

    useEffect(() => {
        if (!tooltipRef.current) {
            tooltipRef.current = document.createElement('div');
            tooltipRef.current.id = 'chartjs-tooltip';
            tooltipRef.current.style.opacity = 0;
            tooltipRef.current.style.position = 'absolute';
            tooltipRef.current.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
            tooltipRef.current.style.color = 'white';
            tooltipRef.current.style.padding = '5px';
            tooltipRef.current.style.borderRadius = '3px';
            document.body.appendChild(tooltipRef.current);
        }

        return () => {
            if (tooltipRef.current) {
                document.body.removeChild(tooltipRef.current);
            }
        };
    }, []);

    const defaultOptions = {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
            enabled: false,
            custom: function(tooltipModel) {
                const tooltipEl = tooltipRef.current;
                if (!tooltipEl) return;

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set Text
                if (tooltipModel.body) {
                    const titleLines = tooltipModel.title || [];
                    const bodyLines = tooltipModel.body.map(b => b.lines);

                    let innerHtml = '<div class="chartjs-tooltip-title">';
                    titleLines.forEach(title => {
                        innerHtml += '<span>' + title + '</span>';
                    });
                    innerHtml += '</div><div class="chartjs-tooltip-body">';

                    bodyLines.forEach((body, i) => {
                        const colors = tooltipModel.labelColors[i];
                        const style = 'background:' + colors.backgroundColor;
                        const span = '<span class="chartjs-tooltip-color-box" style="' + style + '"></span>';
                        innerHtml += '<div>' + span + body + '</div>';
                    });
                    innerHtml += '</div>';

                    tooltipEl.innerHTML = innerHtml;
                }

                // Position tooltip
                if (chartRef.current?.chart) {
                    const position = chartRef.current.chart.canvas.getBoundingClientRect();
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                }
            }
        },
        scales: {
            xAxes: [{
                stacked: true,
                barPercentage: 0.9,
                categoryPercentage: 0.9,
                ticks: {
                    padding: 5
                }
            }],
            yAxes: [{
                stacked: true,
                ticks: {
                    beginAtZero: true,
                    padding: 5
                }
            }]
        },
        layout: {
            padding: {
                left: 10,
                right: 10,
                top: 10,
                bottom: 10
            }
        }
    };

    const mergedOptions = { ...defaultOptions, ...options };

    return (
        <Bar data={data} options={mergedOptions} ref={chartRef} />
    );
});

export default StackBarChartModels;
